import { createApp } from 'vue'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
//firebase
import firebase from 'firebase/compat/app';

const config = {
    apiKey: "AIzaSyDoXALfYePx4SxMqyd-aUJEDQuiqFosqlc",
    authDomain: "bracesco-inversiones.firebaseapp.com",
    databaseURL: "https://bracesco-inversiones-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "bracesco-inversiones",
    storageBucket: "bracesco-inversiones.appspot.com",
    messagingSenderId: "1044009933023",
    appId: "1:1044009933023:web:7a87c8df3a4e4e52f7316a",
    measurementId: "G-4EN7XMCNVG"
  };
//firebase.initializeApp(config);
firebase.initializeApp(config);

//bracesco APP
const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.mount('#app')
